import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Website Integration - Cambozola",
  "path": "/Advanced_User/Website_Integration/Cambozola/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Cambozola JAVA Plugin",
  "image": "./AU_SearchThumb_WebsiteIntegration_Cambozola.png",
  "social": "/images/Search/AU_SearchThumb_WebsiteIntegration_Cambozola.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Integration_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Website Integration - Cambozola' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Cambozola JAVA Plugin' image='/images/Search/AU_SearchThumb_WebsiteIntegration_Cambozola.png' twitter='/images/Search/AU_SearchThumb_WebsiteIntegration_Cambozola.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Webseiten_Einbindung/Cambozola/' locationFR='/fr/Advanced_User/Website_Integration/Cambozola/' crumbLabel="Website" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "website-integration---cambozola",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#website-integration---cambozola",
        "aria-label": "website integration   cambozola permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Website Integration - Cambozola`}</h1>
    <h2 {...{
      "id": "cambozola-java-plugin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#cambozola-java-plugin",
        "aria-label": "cambozola java plugin permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Cambozola JAVA Plugin`}</h2>
    <p>{`In order to integrate your IP camera in a website we recommend you the Cambozola Java plugin. The plugin will allow to display the MJPEG cameras live stream in any web browser. Cambozola is a very simple viewer for multipart jpeg streams that are streamed out by a IP cameras or webcam server.`}</p>
    <p>{`To integrate your INSTAR IP camera in `}<a parentName="p" {...{
        "href": "http://www.andywilcock.com/code/cambozola/config.html"
      }}>{`Cambozola`}</a>{` we recommend you to download the latest version from `}<a parentName="p" {...{
        "href": "http://www.charliemouse.com/code/cambozola/index.html"
      }}>{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "add-a-new-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-new-camera",
        "aria-label": "add a new camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a New Camera`}</h3>
    <p>{`After you have downloaded the newest version, please unpack the zip archive. You will then find different folders and files as shown in the screenshot below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b4795b98caa20dea9e0dfecd93364ea0/41099/Cambozola_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHZgSwX/8QAFhABAQEAAAAAAAAAAAAAAAAAAQAx/9oACAEBAAEFAidb/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFRABAQAAAAAAAAAAAAAAAAAAARD/2gAIAQEABj8Cb//EABgQAQADAQAAAAAAAAAAAAAAAAEAESEx/9oACAEBAAE/ISXg7DmiBvk//9oADAMBAAIAAwAAABCPz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQADAQEAAAAAAAAAAAAAABEAATEhYf/aAAgBAQABPxC/YxoheRnIiUJ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b4795b98caa20dea9e0dfecd93364ea0/e4706/Cambozola_01.avif 230w", "/en/static/b4795b98caa20dea9e0dfecd93364ea0/d1af7/Cambozola_01.avif 460w", "/en/static/b4795b98caa20dea9e0dfecd93364ea0/df5cf/Cambozola_01.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b4795b98caa20dea9e0dfecd93364ea0/a0b58/Cambozola_01.webp 230w", "/en/static/b4795b98caa20dea9e0dfecd93364ea0/bc10c/Cambozola_01.webp 460w", "/en/static/b4795b98caa20dea9e0dfecd93364ea0/b0a15/Cambozola_01.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b4795b98caa20dea9e0dfecd93364ea0/e83b4/Cambozola_01.jpg 230w", "/en/static/b4795b98caa20dea9e0dfecd93364ea0/e41a8/Cambozola_01.jpg 460w", "/en/static/b4795b98caa20dea9e0dfecd93364ea0/41099/Cambozola_01.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b4795b98caa20dea9e0dfecd93364ea0/41099/Cambozola_01.jpg",
              "alt": "Cambozola Camera Integration",
              "title": "Cambozola Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Download and unzip the Cambozola files.`}</p>
    <p>{`Now please copy the folder `}<strong parentName="p">{`DIST`}</strong>{` to your webserver. Please make sure that the folder is in the same directory in which you will place the `}<em parentName="p">{`index.html`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5da10b4a891a662ae7d7e4ecb39b074a/41099/Cambozola_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2JCwf//EABcQAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQEAAQUCCTuN/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERIaFBUf/aAAgBAQABPyG933wdGHg5neH/2gAMAwEAAgADAAAAEHAf/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Qqv/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAAIDAQEAAAAAAAAAAAAAAAERACExQVH/2gAIAQEAAT8QcVCtLBnPMASpVfif/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5da10b4a891a662ae7d7e4ecb39b074a/e4706/Cambozola_02.avif 230w", "/en/static/5da10b4a891a662ae7d7e4ecb39b074a/d1af7/Cambozola_02.avif 460w", "/en/static/5da10b4a891a662ae7d7e4ecb39b074a/df5cf/Cambozola_02.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5da10b4a891a662ae7d7e4ecb39b074a/a0b58/Cambozola_02.webp 230w", "/en/static/5da10b4a891a662ae7d7e4ecb39b074a/bc10c/Cambozola_02.webp 460w", "/en/static/5da10b4a891a662ae7d7e4ecb39b074a/b0a15/Cambozola_02.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5da10b4a891a662ae7d7e4ecb39b074a/e83b4/Cambozola_02.jpg 230w", "/en/static/5da10b4a891a662ae7d7e4ecb39b074a/e41a8/Cambozola_02.jpg 460w", "/en/static/5da10b4a891a662ae7d7e4ecb39b074a/41099/Cambozola_02.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5da10b4a891a662ae7d7e4ecb39b074a/41099/Cambozola_02.jpg",
              "alt": "Cambozola Camera Integration",
              "title": "Cambozola Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select the `}<strong parentName="p">{`DIST`}</strong>{` directory.`}</p>
    <p>{`We are using the program `}<a parentName="p" {...{
        "href": "https://filezilla-project.org/download.php?type=client"
      }}>{`Filezilla`}</a>{` to transfer the files to our webserver. But you can use any kind of FTP program to upload the data.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/177d890d3edea58d1f3c2dcb55ee46b8/41099/Cambozola_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHWsGIGD//EABgQAAIDAAAAAAAAAAAAAAAAAAARARAh/9oACAEBAAEFAsFAhX//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAAAQQEgMf/aAAgBAQAGPwJGQKn/xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhUTFB/9oACAEBAAE/IVoyOyhgcl3wlaVp/9oADAMBAAIAAwAAABCwz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQEBAAMBAQAAAAAAAAAAABEBACExkXGB/9oACAEBAAE/EAbwfZzMFACmr8eWiZBOtJ0T+61dvbv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/177d890d3edea58d1f3c2dcb55ee46b8/e4706/Cambozola_03.avif 230w", "/en/static/177d890d3edea58d1f3c2dcb55ee46b8/d1af7/Cambozola_03.avif 460w", "/en/static/177d890d3edea58d1f3c2dcb55ee46b8/df5cf/Cambozola_03.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/177d890d3edea58d1f3c2dcb55ee46b8/a0b58/Cambozola_03.webp 230w", "/en/static/177d890d3edea58d1f3c2dcb55ee46b8/bc10c/Cambozola_03.webp 460w", "/en/static/177d890d3edea58d1f3c2dcb55ee46b8/b0a15/Cambozola_03.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/177d890d3edea58d1f3c2dcb55ee46b8/e83b4/Cambozola_03.jpg 230w", "/en/static/177d890d3edea58d1f3c2dcb55ee46b8/e41a8/Cambozola_03.jpg 460w", "/en/static/177d890d3edea58d1f3c2dcb55ee46b8/41099/Cambozola_03.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/177d890d3edea58d1f3c2dcb55ee46b8/41099/Cambozola_03.jpg",
              "alt": "Cambozola Camera Integration",
              "title": "Cambozola Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Upload the `}<strong parentName="p">{`DIST`}</strong>{` directory to your webserver.`}</p>
    <p>{`Now please create a simple html file. In this file please include the following Code and save it as _index.html__:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`applet code`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"com.charliemouse.cambozola.Viewer"`}</span>{`

archive`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"dist/cambozola.jar"`}</span>{` width`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"640"`}</span>{` height`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"480"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`param name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"url"`}</span>{` value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"http://username:password@xxx.dyndns.org:80/videostream.cgi"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`applet`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`It is very important that in the 3rd line (value=...) you replace the username and the password with the username and password that you have set in your IP camera. Also the internet address of the camera has to be exchanged with the correct internet address of your camera. You can compare the code above with our sample code below to have a better understanding. If you didn´t change the username and password in the camera the default username is "admin" and no password.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4233f55846472f719ba5c3e896c8b1c3/41099/Cambozola_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB100Ccf/EABoQAAICAwAAAAAAAAAAAAAAAAASAQIQETL/2gAIAQEAAQUCTYgsFecf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAHBAAAQMFAAAAAAAAAAAAAAAAAAERkRAhMWFx/9oACAEBAAE/IUYe0CJ1BygwV//aAAwDAQACAAMAAAAQkM//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAgEFAAAAAAAAAAAAAAABABEQQVFhkaH/2gAIAQEAAT8QI2izlAIq4q06J55tj//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4233f55846472f719ba5c3e896c8b1c3/e4706/Cambozola_04.avif 230w", "/en/static/4233f55846472f719ba5c3e896c8b1c3/d1af7/Cambozola_04.avif 460w", "/en/static/4233f55846472f719ba5c3e896c8b1c3/df5cf/Cambozola_04.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4233f55846472f719ba5c3e896c8b1c3/a0b58/Cambozola_04.webp 230w", "/en/static/4233f55846472f719ba5c3e896c8b1c3/bc10c/Cambozola_04.webp 460w", "/en/static/4233f55846472f719ba5c3e896c8b1c3/b0a15/Cambozola_04.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4233f55846472f719ba5c3e896c8b1c3/e83b4/Cambozola_04.jpg 230w", "/en/static/4233f55846472f719ba5c3e896c8b1c3/e41a8/Cambozola_04.jpg 460w", "/en/static/4233f55846472f719ba5c3e896c8b1c3/41099/Cambozola_04.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4233f55846472f719ba5c3e896c8b1c3/41099/Cambozola_04.jpg",
              "alt": "Cambozola Camera Integration",
              "title": "Cambozola Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Create a simple `}<em parentName="p">{`index.html`}</em>{` file with the content seen here.`}</p>
    <p>{`Please upload the file `}<em parentName="p">{`index.html`}</em>{` in the same folder in which you earlier copied the folder `}<strong parentName="p">{`DIST`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/deab06aad2971963b7e1a289946caf02/41099/Cambozola_05.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHr0shoT//EABcQAAMBAAAAAAAAAAAAAAAAAAAQESH/2gAIAQEAAQUChhiq/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAACAxQf/aAAgBAQAGPwKEMT//xAAbEAEAAgIDAAAAAAAAAAAAAAABADERIXGBkf/aAAgBAQABPyHSxL4ROPiIpZUwG53P/9oADAMBAAIAAwAAABCz/wD/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPxBX/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8QR//EABwQAQADAAMBAQAAAAAAAAAAAAEAESExYZHRUf/aAAgBAQABPxACcnZyRFR66Y6z0fJSxMGr8hSLv82KXy9Z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/deab06aad2971963b7e1a289946caf02/e4706/Cambozola_05.avif 230w", "/en/static/deab06aad2971963b7e1a289946caf02/d1af7/Cambozola_05.avif 460w", "/en/static/deab06aad2971963b7e1a289946caf02/df5cf/Cambozola_05.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/deab06aad2971963b7e1a289946caf02/a0b58/Cambozola_05.webp 230w", "/en/static/deab06aad2971963b7e1a289946caf02/bc10c/Cambozola_05.webp 460w", "/en/static/deab06aad2971963b7e1a289946caf02/b0a15/Cambozola_05.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/deab06aad2971963b7e1a289946caf02/e83b4/Cambozola_05.jpg 230w", "/en/static/deab06aad2971963b7e1a289946caf02/e41a8/Cambozola_05.jpg 460w", "/en/static/deab06aad2971963b7e1a289946caf02/41099/Cambozola_05.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/deab06aad2971963b7e1a289946caf02/41099/Cambozola_05.jpg",
              "alt": "Cambozola Camera Integration",
              "title": "Cambozola Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Copy the `}<em parentName="p">{`index.html`}</em>{` to the same folder you used earlier.`}</p>
    <p>{`Please upload the file `}<em parentName="p">{`index.html`}</em>{` in the same folder in which you earlier copied the folder `}<strong parentName="p">{`DIST`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8d35ae9c30440630b62431c8e6f064ba/41099/Cambozola_06.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "7.826086956521738%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAACABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdGYWA//xAAWEAADAAAAAAAAAAAAAAAAAAAAARD/2gAIAQEAAQUCFP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABcQAAMBAAAAAAAAAAAAAAAAAAABMRD/2gAIAQEAAT8hcRSz/9oADAMBAAIAAwAAABDzz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQEAAwEAAAAAAAAAAAAAAAEAESExUf/aAAgBAQABPxBPRt80h4X/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d35ae9c30440630b62431c8e6f064ba/e4706/Cambozola_06.avif 230w", "/en/static/8d35ae9c30440630b62431c8e6f064ba/d1af7/Cambozola_06.avif 460w", "/en/static/8d35ae9c30440630b62431c8e6f064ba/df5cf/Cambozola_06.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8d35ae9c30440630b62431c8e6f064ba/a0b58/Cambozola_06.webp 230w", "/en/static/8d35ae9c30440630b62431c8e6f064ba/bc10c/Cambozola_06.webp 460w", "/en/static/8d35ae9c30440630b62431c8e6f064ba/b0a15/Cambozola_06.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d35ae9c30440630b62431c8e6f064ba/e83b4/Cambozola_06.jpg 230w", "/en/static/8d35ae9c30440630b62431c8e6f064ba/e41a8/Cambozola_06.jpg 460w", "/en/static/8d35ae9c30440630b62431c8e6f064ba/41099/Cambozola_06.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8d35ae9c30440630b62431c8e6f064ba/41099/Cambozola_06.jpg",
              "alt": "Cambozola Camera Integration",
              "title": "Cambozola Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Copy the `}<em parentName="p">{`index.html`}</em>{` to the same folder you used earlier.`}</p>
    <p>{`After successfully uploading the file `}<em parentName="p">{`index.html`}</em>{` please open it by typing in the path in your web browser.`}</p>
    <p>{`If you are opening the website for the first time in can take a short while until the java plugin has started. It might also happen that a security warning will show up. Please make a mark that it should always be allowed to use this applet. After that please select `}<em parentName="p">{`Allow`}</em>{`. This is necessary so that the java plugin gets the necessary access right to show the live stream.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0df41eed3ddbe54efa2efa50a912cc43/f93b5/Cambozola_08.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdaAbhP/xAAWEAEBAQAAAAAAAAAAAAAAAAABABD/2gAIAQEAAQUCcL//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAADAQAAAAAAAAAAAAAAAAAAARAx/9oACAEBAAY/AjHP/8QAGxABAAAHAAAAAAAAAAAAAAAAAQAQETFBgZH/2gAIAQEAAT8hNMOiQBLdj//aAAwDAQACAAMAAAAQeA//xAAVEQEBAAAAAAAAAAAAAAAAAAAAAf/aAAgBAwEBPxBX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGRABAQEBAQEAAAAAAAAAAAAAEQEAIWGh/9oACAEBAAE/EJAC29+WJBLzLHO5/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0df41eed3ddbe54efa2efa50a912cc43/e4706/Cambozola_08.avif 230w", "/en/static/0df41eed3ddbe54efa2efa50a912cc43/e47c0/Cambozola_08.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0df41eed3ddbe54efa2efa50a912cc43/a0b58/Cambozola_08.webp 230w", "/en/static/0df41eed3ddbe54efa2efa50a912cc43/c85cb/Cambozola_08.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0df41eed3ddbe54efa2efa50a912cc43/e83b4/Cambozola_08.jpg 230w", "/en/static/0df41eed3ddbe54efa2efa50a912cc43/f93b5/Cambozola_08.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0df41eed3ddbe54efa2efa50a912cc43/f93b5/Cambozola_08.jpg",
              "alt": "Cambozola Camera Integration",
              "title": "Cambozola Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Start the Java Plugin.`}</p>
    <p>{`After you have now confirmed the security warning the live stream will be shown in your web browser.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ca2e9001d55489e8d8c8c227a366147b/41099/Cambozola_09.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdJZlqXjB//EABwQAAEDBQAAAAAAAAAAAAAAAAIAAQMEERITMv/aAAgBAQABBQJXQszjKFRtAZMR5//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AUn/xAAZEAADAQEBAAAAAAAAAAAAAAAAAQIREBL/2gAIAQEABj8C4inM1mi9S9wR/8QAGhABAQEBAAMAAAAAAAAAAAAAAQARMSFBUf/aAAgBAQABPyHniYgIM3dSzGy/sC/YoT2//9oADAMBAAIAAwAAABBEz//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EBn/xAAWEQEBAQAAAAAAAAAAAAAAAAARARD/2gAIAQIBAT8QCLn/xAAYEAEBAQEBAAAAAAAAAAAAAAABESEAMf/aAAgBAQABPxCzREyciS5ygxPXtECpRLk3mYosl15xIoDR7//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca2e9001d55489e8d8c8c227a366147b/e4706/Cambozola_09.avif 230w", "/en/static/ca2e9001d55489e8d8c8c227a366147b/d1af7/Cambozola_09.avif 460w", "/en/static/ca2e9001d55489e8d8c8c227a366147b/df5cf/Cambozola_09.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ca2e9001d55489e8d8c8c227a366147b/a0b58/Cambozola_09.webp 230w", "/en/static/ca2e9001d55489e8d8c8c227a366147b/bc10c/Cambozola_09.webp 460w", "/en/static/ca2e9001d55489e8d8c8c227a366147b/b0a15/Cambozola_09.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca2e9001d55489e8d8c8c227a366147b/e83b4/Cambozola_09.jpg 230w", "/en/static/ca2e9001d55489e8d8c8c227a366147b/e41a8/Cambozola_09.jpg 460w", "/en/static/ca2e9001d55489e8d8c8c227a366147b/41099/Cambozola_09.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ca2e9001d55489e8d8c8c227a366147b/41099/Cambozola_09.jpg",
              "alt": "Cambozola Camera Integration",
              "title": "Cambozola Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And you are done!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      